import React from 'react';
import Newsletter from '../components/preInscricao';
import { useNavigate } from 'react-router-dom';

import { FaPhone } from "react-icons/fa6";
import { MdEmail, MdHome } from "react-icons/md";

const Contacto = () => {
    const navigate = useNavigate();

    return (
        <>
<main className='px-8 lg:px-24'>
    <h1 className="pt-6 text-center title">
        Entra em contacto
    </h1>

<div className='grid grid-cols-1 gap-3 mt-12 sm:grid-cols-2'>
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <FaPhone className="w-5 h-6 text-yellow-500" />
        </div>
        <a href="tel:214863554" className='text-black dark:text-white'>214 863 554</a>
    </div>
   
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <MdEmail className="w-6 h-6 text-yellow-500" />
        </div>
        <a href="mailto:ecac426@gmail.com" className='text-black dark:text-white'>ecac426@gmail.com</a>
    </div>
   
    <div className='flex items-center gap-4 w-fit'>
        <div className='p-3 rounded-full bg-brandDarkBlue'>
            <MdHome className="w-6 h-6 text-yellow-500" />
        </div>
        <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.7415208205102!2d-9.422974524260907!3d38.70078127176659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ec5a7f0784843%3A0xe04983513261625e!2sEscola%20de%20condu%C3%A7%C3%A3o%20Costa%20do%20sol!5e0!3m2!1spt-PT!2spt!4v1723225188928!5m2!1spt-PT!2spt" className='text-black dark:text-white' target="_blank" rel="noopener noreferrer">
            Praça Doutor Francisco Sá Carneiro, Nº 3-1º ANDAR, 3ª APORTA, Cascais, Portugal
        </a>
    </div>
</div>


				<div className="flex flex-col mt-12 items-center-center sm:mt-6">
					<h3 className="text-center subtitle sm:text-left">Onde estamos</h3>
					<iframe
						className="w-[95%] sm:w-full mx-auto mt-2 sm:h-full sm:mx-0"
						style={{ height: "400px" }}
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.7415208205102!2d-9.422974524260907!3d38.70078127176659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ec5a7f0784843%3A0xe04983513261625e!2sEscola%20de%20condu%C3%A7%C3%A3o%20Costa%20do%20sol!5e0!3m2!1spt-PT!2spt!4v1723225188928!5m2!1spt-PT!2spt"						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>



                <Newsletter />
            </main >
        </>
    );
};
export default Contacto;